import React from 'react'
import cx from 'classnames'
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/github'
import styles from './CodeBlock.module.scss'
import Copy from './../../assets/svg/copy.inline.svg'
import select from 'select'

const Codeblock = ({ children, className, ...restProps }) => {
  let ref = React.useRef(null)
  let container = React.useRef(null)

  let [hovered, setHovered] = React.useState(false)
  let [copied, setCopied] = React.useState(false)

  React.useEffect(() => {
    let t
    if (copied) {
      t = setTimeout(() => {
        setCopied(false)
      }, 2500)
    }
    return () => {
      clearTimeout(t)
    }
  }, [copied])

  function stripLeadingIndentation(str) {
    const lines = str.split('\n')
    const indent = Math.min(
      ...lines.filter(line => line.trim()).map(line => line.search(/\S/))
    )
    return lines
      .map(line => line.substring(indent))
      .join('\n')
      .trim()
  }

  const copyCode = () => {
    if (ref.current) {
      if (typeof document !== 'undefined' && typeof window !== 'undefined') {
        let fakeEl = document.createElement('textarea')
        fakeEl.value = ref.current.innerText
        fakeEl.style.fontSize = '12pt'
        fakeEl.style.border = '0'
        fakeEl.style.padding = '0'
        fakeEl.style.margin = '0'
        fakeEl.style.position = 'absolute'
        fakeEl.style.opacity = 0
        let yPosition = window.pageYOffset || document.documentElement.scrollTop
        fakeEl.style.top = `${yPosition}px`
        container.current.appendChild(fakeEl)
        select(fakeEl)
        document.execCommand('copy')
        setCopied(true)
        container.current.removeChild(fakeEl)
      }
    }
  }

  const language = className.replace(/language-/, '')
  return (
    <Highlight
      {...defaultProps}
      code={stripLeadingIndentation(children)}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <div
          className={styles.parent}
          onMouseEnter={() => setHovered(true)}
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <header
            ref={container}
            className={cx(styles.lang, styles[language.toLowerCase()])}
          >
            {language.toUpperCase() === 'SHELL' ? (
              <>
                <span
                  style={{
                    fontFamliy: "'Roboto Mono', monospace",
                    color: '#FFFFFF',
                    marginRight: 1,
                  }}
                >
                  $
                </span>
                _
              </>
            ) : (
              language.toUpperCase()
            )}
          </header>
          <button
            type="button"
            className={cx({
              [styles.copy]: true,
              [styles.hovered]: hovered,
            })}
            onClick={copyCode}
          >
            <Copy className={styles.icon} />
            {copied ? 'Copied' : 'Copy'}
          </button>
          <pre className={cx(className, styles.root)} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
          <pre style={{ display: 'none' }}>
            <code ref={ref}>{children}</code>
          </pre>
        </div>
      )}
    </Highlight>
  )
}

export default Codeblock
