import styles from './PostLayout.module.scss'
import React from 'react'
import cx from 'classnames'
import { MDXProvider } from '@mdx-js/react'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'
import { graphql } from 'gatsby'
import { format, parseISO } from 'date-fns'

import Text from './../../components/Text/Text'
import Navbar from '../../components/Navbar/Navbar'
import Container from '../Container/Container'
import CodeBlock from './../CodeBlock/CodeBlock'
import SEO from './../../utils/seo'

const Code = props => (
  <Container post>
    <main style={{ marginLeft: -20, marginRight: -20 }}>
      <CodeBlock {...props} />
    </main>
  </Container>
)

const Heading1 = props => (
  <Container post>
    <Text heading1 className={styles.heading1} as="h1" {...props}>
      {props.children}
    </Text>
  </Container>
)
const Heading2 = props => (
  <Container post>
    <Text heading2 className={styles.heading2} as="h2" {...props}>
      {props.children}
    </Text>
  </Container>
)
const Heading3 = props => (
  <Container post>
    <Text heading3 className={styles.heading3} as="h3" {...props}>
      {props.children}
    </Text>
  </Container>
)
const Heading4 = props => (
  <Container post>
    <Text heading4 className={styles.heading4} as="h4" {...props}>
      {props.children}
    </Text>
  </Container>
)
const Heading5 = props => (
  <Container post>
    <Text heading5 className={styles.heading5} as="h5" {...props}>
      {props.children}
    </Text>
  </Container>
)
const Heading6 = props => (
  <Container post>
    <Text heading6 className={styles.heading6} as="h6" {...props}>
      {props.children}
    </Text>
  </Container>
)

const Paragraph = props => (
  <Container post>
    <Text medium className={styles.paragraph} as="p" {...props}>
      {props.children}
    </Text>
  </Container>
)

const ListContainer = props => (
  <Container post>
    <ol className={styles.listContainer} {...props}>
      {props.children}
    </ol>
  </Container>
)

const ListItem = props => (
  <Text heading5 className={styles.listItem} as="li" {...props}>
    {props.children}
  </Text>
)

const PostLayout = ({ data: { mdx }, children, className, ...restProps }) => {
  let mappedComponents = {
    code: Code,
    h1: Heading1,
    h2: Heading2,
    h3: Heading3,
    h4: Heading4,
    h5: Heading5,
    h6: Heading6,
    p: Paragraph,
    li: ListItem,
    ol: ListContainer,
    ul: ListContainer,
  }

  return (
    <>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
      />
      <Navbar light />
      <article className={styles.root}>
        <Container post>
          <header style={{ marginBottom: 12 }}>
            <Text medium>
              {format(parseISO(mdx.frontmatter.date), 'MMM dd, yyyy')}
            </Text>
            <Text large style={{ marginLeft: 6, marginRight: 6 }}>
              •
            </Text>
            <Text medium>{mdx.timeToRead} min read</Text>
          </header>
        </Container>
        <MDXProvider {...restProps} components={mappedComponents}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </article>
    </>
  )
}

export default PostLayout

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      frontmatter {
        title
        description
        path
        date
        keywords
      }
      tableOfContents(maxDepth: 10)
    }
  }
`
